import React from "react"
import { LangContext } from "../components/globals/lang-context"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { Gallery } from "../components/sections"

const GallPage = () => (
  <Layout>
    <LangContext.Consumer>
      {({ lang }) => (
        <>
          <SEO title="Contact" />
          <Gallery lang={lang} />
        </>
      )}
    </LangContext.Consumer>
  </Layout>
)

export default GallPage
